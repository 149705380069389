



























.info_data_wrapper {
  background-color: #fff;
  padding: 0.7rem;
  font-weight: 400;
  font-size: 1rem;
  color: #212529;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 30px;
  text-align: right;
  .items_container {
    padding: 12px 8px;
  }
  .items_container:nth-child(even) {
    // color: blue;
  }
  .items_container:nth-child(odd) {
    // color: red;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
